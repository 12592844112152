.about-container{
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    height: 100%;
    margin-bottom: 50px;
    margin-bottom: 55px;
}

.about-body{
    width: 85%;
}

.header-box{
    position: relative; 
    z-index: 1;   
    margin-top: 10px;
    width: 98%;
}

.header-box::after{
    content: "";
    background: url('https://images.pexels.com/photos/358502/pexels-photo-358502.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260');
    opacity: 1;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    position: absolute;
    background-size: cover;
    background-position: center 15%;
    z-index: -1;

}
.header-content{ /*  */
    padding: 10px 25px;
    background: rgba(0,0,0,.4);
    border-right: 5px solid red;
    border-left: 5px solid red;
    color: white;
}

.who-wr{
    padding-bottom: 5px;
    border-bottom: 1px solid white;
}
.h2{
    font-size: 36px;
    font-weight: bold;
}

.tm{
    padding: 5px;
}

.bg-img{
    background-image: url('https://images.pexels.com/photos/1040157/pexels-photo-1040157.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260');
    
}

.team{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.team-txt{
    font-size: 40px;
    color: white;
    text-shadow: 1px 1px 1px rgba(0,0,0,.8);
    padding-bottom: 5px;
    display: flex;
    border-bottom: 1px solid black;
    justify-content: center;
    width: 110%;
    margin-bottom: 5px;
}

.team-container{
    display: flex;
    

}
.divide{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

}
.line{
    background: black;
    width: 2px;
    height: 40%;
    margin: 5px;
}

.featured{

}

.feat-section{
    
}

.feat-pic{

}

.feat-name{

}

.feat-text{

}

.team-box{

}

.member{

}

.member-name{

}

.member-title{

}

@media only screen and (max-width: 420px){
    .team-container{
        flex-direction: column;
    }
    .divide{
        flex-direction: row;
    }
    .line{
        width: 40%;
        height: 2px;
    }
}