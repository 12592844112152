.contact-container {
  display: flex;
  justify-content: center;
  width: 100%;
  min-height: 100vh;
  background: #f5f5f5;
}

.contact-bg {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  background: url(https://images.pexels.com/photos/416673/pexels-photo-416673.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260);
  align-items: center;
  min-height: 100vh;
}

.c-form {
  display: flex;
  flex-direction: column;
  padding: 20px;
  background: rgba(0, 0, 0, 0.863);
  color: white;
  height: 70%;
  width: 90%;
  max-width: 600px;
  margin: 50px;
  border-radius: 5px;
  box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.767);
  font-weight: 600;
  font-size: 20px;
}
textarea {
  background-color: rgba(248, 248, 255, 0.699);
}
.c-form-hdr {
}

.form-name {
  width: 50%;
}

.form-services {
}

.form-company {
}

.form-ipt {
  margin: 10px 0;
}
textarea:focus + label {
  color: white;
}

label {
  color: white;
}
label:focus {
  color: white;
}
.MuiInputLabel-outlined-15 {
  z-index: 1;
  transform: translate(14px, 20px) scale(1);
  pointer-events: none;
  color: white;
}
label.MuiFormLabel-root-16.MuiFormLabel-focused-17 {
  color: rgb(255, 229, 172);
  text-shadow: 1px 1px 1px rgba(20, 20, 20, 0.911);
}
label.MuiInputLabel-formControl-10 {
  color: white;
}

#JotFormIFrame-92088491672871 {
  height: 100vh;
}

div.formFooter.summer-sale-2018 {
  display: none;
}
.supernova {
  background: black;
}
