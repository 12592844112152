.infobox {
  display: flex;
  flex-direction: column;
}

.name-section {
  display: flex;
  flex-direction: column;
  display: inline-block;
}
.name {
  font-size: 25px;
  font-weight: 600;
}
.title {
  font-size: 17px;
  color: white;
  background: rgba(0, 0, 0, 0.2);
  padding: 3px 10px 3px 5px;
  font-weight: 500;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.342);
}

.title-1 {
  border-right: 4px solid red;
}
.title-2 {
  border-top: 1px black solid;
  border-bottom: 1px black solid;
  border-right: 4px solid black;
  display: inline-flex;
}

.details-sec {
  margin-top: 10px;
  position: relative;
  padding-left: 5px;
  z-index: 2;
  display: flex;
  flex-direction: column;
}
.outline {
  position: absolute;
  top: -5px;
  left: 0;
  z-index: -1;
  height: 90%;
  background: white;
  box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.3);
  width: 100%;
}
.outline.two {
  top: unset;
  left: unset;
  bottom: -5;
  right: 0;
  width: 80%;
}

.creds {
  background: red;
  box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.442);
  width: 80%;
  font-style: italic;
  align-self: flex-end;
  margin-top: 20px;
  padding: 10px;
  color: white;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.34);
}
