body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

* {
  box-sizing: border-box;
}
footer {
  position: fixed;
  bottom: 0;
  background: black;
  width: 100%;
  color: white;
  display: flex;
  justify-content: center;
  padding: 5px;
  font-size: 12px;
  z-index: 3;
}
.main-container {
  display: flex;
  flex-direction: column;
  position: relative;
  background-color: rgb(245, 240, 225);
  align-items: center;
  min-height: 100vh;
}

.nav-cont {
  width: 100%;
  display: flex;
  flex-direction: column;
  background: white;
  z-index: 1;
}

.logo {
  height: auto;
  width: 20%;
}

.nav-top {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.493);
}

.nav-red {
  width: 100%;
  position: relative;
  background: rgb(231, 26, 22);

  height: 15px;
}
.nav-black {
  width: 100%;
  position: relative;
  background: rgb(0, 0, 0);
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom-left-radius: 20px;
  border-top-left-radius: 20px;
}

.nav-btn {
  color: white;
  padding: 0px 25px;
  cursor: pointer;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.nav-btn:hover {
  background-color: rgba(211, 211, 211, 0.377);
  border-bottom: 3px solid rgb(231, 26, 22);
}

a {
  text-decoration: unset;
  height: 100%;
}

@media only screen and (max-width: 420px) {
  nav {
  }
  .nav-top {
    flex-direction: column;
    display: flex;
  }
  .nav-black {
    border-radius: unset;
  }
  .main-bottom {
    width: 100%;
    font-size: 12px;
  }
  .main-bottom > .top-phrase {
    width: 90%;
    font-size: 14px;
  }
  .main-bottom > .bottom-phrase {
    width: 90%;
    font-size: 14px;
  }
  .slogan {
    width: 100%;
  }
}

.home-container {
  position: relative;
  display: flex;
  flex-direction: column;

  height: 100vh;
  align-items: center;
  width: 100%;
  justify-content: center;
}
.main-image {
  background-image: url("https://images.pexels.com/photos/128867/coins-currency-investment-insurance-128867.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260");
  height: 100%;
  width: 100%;
  background-position: bottom;
  position: absolute;
  top: 0;
  left: 0;
}

.slogan {
  display: flex;
  position: relative;
  font-size: 90px;
  text-shadow: 2px 1px 1px black;
  color: white;
  flex-direction: column;
  height: 100%;
  z-index: 1;
  width: 70%;
  max-width: 860px;
  align-items: center;
}

.row {
  width: 100%;
  margin-top: 15px;
  display: flex;
}

.phrase {
  display: inline-block;
  background: rgba(196, 196, 196, 0.664);
  padding-right: 20px;
}

.phrase.red {
}

.main-bottom {
  margin: 6% 0;
  display: flex;
  height: 25%;
  width: 50%;
  background-color: rgba(0, 0, 0, 0.644);
  flex-direction: column;
  border-top: 0.5px solid white;
  border-bottom: 0.5px solid white;
  border-bottom-right-radius: 20px;
}
.top-phrase {
  padding: 10px;
  height: 50%;
  display: flex;
  align-items: center;
  text-align: center;
  font-size: 20px;
  background-color: black;
  position: relative;
}

.top-phrase-bottom-bar {
  height: 1px;
  width: 60%;
  background-color: white;
  position: absolute;
  bottom: -1px;
  left: 20%;
}
.bottom-phrase {
  padding: 10px;
  height: 50%;
  display: flex;
  align-items: center;
  text-align: center;
  font-size: 20px;
  justify-content: center;
}

.slide-info {
  display: flex;
  flex-direction: column;
  width: 100%;
  position: relative;
  height: 70%;
  justify-content: center;
}

.main-text {
  position: relative;
  margin-top: 20px;
  width: 55%;
  background: rgba(161, 161, 161, 0.801);
  margin-right: 12px;
  max-height: 90%;
  display: flex;
  flex-direction: column;
  align-items: space-around;
  justify-content: center;
}

.main-text > div {
  margin: 10px 10px;
  font-size: 1.6vw;
}

.red-top {
  border-top: 4px solid rgb(231, 26, 22);
}

.black-top {
  border-top: 4px solid black;
}

.btn-box {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 45%;
  margin-bottom: 15px;
}
.slideBtn {
  position: absolute;
}
.learn-more {
  display: flex;
  justify-content: center;
  font-size: 2.5vw;
  background: black;
  padding: 10px;
  border-radius: 3px;
  border: 1px solid white;
  cursor: pointer;
}

.learn-more:hover {
  background: rgba(0, 0, 0, 0.671);
  color: rgba(255, 0, 0, 0.849);
}

.hidden {
  display: none;
}

.str-box {
  display: flex;
  width: 80%;
  justify-content: space-between;
  margin-top: 10px;
  align-items: flex-start;
}

.str {
  display: flex;
  justify-content: center;
  height: 50px;
  width: 100%;
  align-items: center;
  border-top: 6px solid rgb(231, 26, 22);
  border-right: 6px solid black;
  border-left: 6px solid black;
  border-bottom: 6px solid black;
  cursor: pointer;
  border-radius: 4px;
}

.str:hover {
  background: rgba(196, 196, 196, 0.664);
  color: white;
  -webkit-transition: 0.2s;
  transition: 0.2s;
}

.str-content {
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 30%;
  align-items: center;
}

.str-icon {
  margin-right: 10px;
}

.str-txt {
  font-weight: 600;
}

.bullet-pts {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: 0 0 15px 0;
}

.bullet-pts > li {
  align-self: flex-start;
  text-align: left;
}

.big-icon {
  padding: 0 50px;
  border-right: 4px solid black;
  border-left: 4px solid black;
  margin-top: 15px;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
}
.big-icon > .svg-inline--fa.fa-w-12 {
  width: 85px;
  height: auto;
}

.big-icon > .svg-inline--fa.fa-w-10 {
  width: 68px;
  height: auto;
  margin-top: 5px;
}

.bullet-pts.two {
  display: none;
}

@media only screen and(min-width: 1300px) {
  .main-text > div {
    font-size: 21px;
  }
}
/* @media(max-width: 1120px){
    .main-text > div{
        font-size: 18px;
    }
} */

@media only screen and (max-height: 850px) and (min-width: 420px) {
  .main-bottom {
  }
  .top-phrase {
    font-size: 15px;
  }
  .bottom-phrase {
    font-size: 15px;
  }
  .row {
    font-size: 70px;
  }
}

@media only screen and (max-width: 420px) {
  .logo {
    width: 75%;
  }
  .nav-btn {
    padding: 0 15px;
  }
  .phrase {
  }
  .main-container {
  }
  .main-image {
  }
  .main-bottom {
    align-items: center;
    height: unset;
    width: 90%;
    align-self: center;
  }
  .main-text {
    margin: 30px 0;
    max-height: unset;
    width: 90%;
    padding: 10px;
  }
  .main-text > div {
    margin: unset;
    font-size: 18px;
    margin-top: 10px;
    padding: 5px;
  }
  .btn-box {
    width: 100%;
    flex-direction: column;
  }
  .learn-more {
    font-size: 25px;
    margin-bottom: 10px;
  }
  .str-box {
    flex-direction: column;
    align-items: center;
    width: 100%;
  }
  .str-content {
    width: 80%;
  }
  .bullet-pts {
    display: none;
  }
  .bullet-pts.two {
    display: unset;
  }
  .big-icon {
    display: none;
  }
  .slogan {
    font-size: 45px;
    width: 100%;
  }
}

@media only screen and (max-width: 375px) {
  .slogan {
    font-size: 34px;
  }
  .main-bottom {
    font-size: 15px;
  }
}

.checkbox{
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    width: 50px;
    height: 50px;
    margin-left: 10px;
}

.box{
    border: white 1px solid;
}

.box.tl{
    grid-column: 1;
    grid-row: 1;
}
.box.tr{
    grid-column: 2;
    grid-row: 1;
}
.box.bl{
    grid-column: 1;
    grid-row: 2;
}
.box.br{
    grid-column: 2;
    grid-row: 2;
}

.white{
    background: white;
}

.rd{
    background: rgb(231, 26, 22);
}

.blk{
    background: black;
}

.centerBox{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 30px;
    height: 30px;
    margin: 10px;
    background: black;
    border: 3px solid white;
}

.center{
    width: 50%;
    height: 50%;
}
.about-container{
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    height: 100%;
    margin-bottom: 50px;
    margin-bottom: 55px;
}

.about-body{
    width: 85%;
}

.header-box{
    position: relative; 
    z-index: 1;   
    margin-top: 10px;
    width: 98%;
}

.header-box::after{
    content: "";
    background: url('https://images.pexels.com/photos/358502/pexels-photo-358502.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260');
    opacity: 1;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    position: absolute;
    background-size: cover;
    background-position: center 15%;
    z-index: -1;

}
.header-content{ /*  */
    padding: 10px 25px;
    background: rgba(0,0,0,.4);
    border-right: 5px solid red;
    border-left: 5px solid red;
    color: white;
}

.who-wr{
    padding-bottom: 5px;
    border-bottom: 1px solid white;
}
.h2{
    font-size: 36px;
    font-weight: bold;
}

.tm{
    padding: 5px;
}

.bg-img{
    background-image: url('https://images.pexels.com/photos/1040157/pexels-photo-1040157.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260');
    
}

.team{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.team-txt{
    font-size: 40px;
    color: white;
    text-shadow: 1px 1px 1px rgba(0,0,0,.8);
    padding-bottom: 5px;
    display: flex;
    border-bottom: 1px solid black;
    justify-content: center;
    width: 110%;
    margin-bottom: 5px;
}

.team-container{
    display: flex;
    

}
.divide{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

}
.line{
    background: black;
    width: 2px;
    height: 40%;
    margin: 5px;
}

.featured{

}

.feat-section{
    
}

.feat-pic{

}

.feat-name{

}

.feat-text{

}

.team-box{

}

.member{

}

.member-name{

}

.member-title{

}

@media only screen and (max-width: 420px){
    .team-container{
        flex-direction: column;
    }
    .divide{
        flex-direction: row;
    }
    .line{
        width: 40%;
        height: 2px;
    }
}
.header{
    display: flex;
    justify-content: center;
    align-items: center;

}

.header-txt{
    color: white;
    margin: 25px 10px;
}
.infobox {
  display: flex;
  flex-direction: column;
}

.name-section {
  display: flex;
  flex-direction: column;
  display: inline-block;
}
.name {
  font-size: 25px;
  font-weight: 600;
}
.title {
  font-size: 17px;
  color: white;
  background: rgba(0, 0, 0, 0.2);
  padding: 3px 10px 3px 5px;
  font-weight: 500;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.342);
}

.title-1 {
  border-right: 4px solid red;
}
.title-2 {
  border-top: 1px black solid;
  border-bottom: 1px black solid;
  border-right: 4px solid black;
  display: inline-flex;
}

.details-sec {
  margin-top: 10px;
  position: relative;
  padding-left: 5px;
  z-index: 2;
  display: flex;
  flex-direction: column;
}
.outline {
  position: absolute;
  top: -5px;
  left: 0;
  z-index: -1;
  height: 90%;
  background: white;
  box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.3);
  width: 100%;
}
.outline.two {
  top: unset;
  left: unset;
  bottom: -5;
  right: 0;
  width: 80%;
}

.creds {
  background: red;
  box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.442);
  width: 80%;
  font-style: italic;
  align-self: flex-end;
  margin-top: 20px;
  padding: 10px;
  color: white;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.34);
}

.services-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
  width: 100%;
  position: relative;
}

.header-box.services {
  min-height: 750px;
}

.header-box.services::after {
  content: "";
  background: url("https://images.pexels.com/photos/358502/pexels-photo-358502.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260");
  opacity: 1;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  position: absolute;
  background-size: cover;
  background-position: center 15%;
  z-index: -1;
}

.services-content {
  height: 80%;
  display: flex;
  position: relative;
  justify-content: center;
}

.services-main-ct {
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: rgba(0, 0, 0, 0.766);
  padding: 15px;
}

.services-btns {
  flex-direction: column;
  justify-content: center;
  width: 45%;
  align-items: center;
  display: flex;
  height: 75%;
  align-self: center;
}

.service-btn {
  font-size: 38px;
  padding: 10px;
  background: rgba(0, 0, 0, 0);
  width: 90%;
  margin: 15px 0;
  display: flex;
  justify-content: center;
  border: 2px solid white;
  background-color: black;
  color: white;
  cursor: pointer;
  position: relative;
  z-index: 2;
  text-shadow: 1px 1px 1px black;
}

.expense-bg {
  background: url("https://images.pexels.com/photos/53621/calculator-calculation-insurance-finance-53621.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260");
  background-position: center 45%;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  position: absolute;
  background-size: cover;
  z-index: -1;
}
.expense-bg:hover {
  opacity: 0.4;
}

.divinc-bg {
  background: url("https://images.pexels.com/photos/437345/pexels-photo-437345.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260");
  background-position: center 40%;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  position: absolute;
  background-size: cover;
  z-index: -1;
}
.revenue-bg {
  background: url("https://images.pexels.com/photos/95916/pexels-photo-95916.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260");
  background-position: center 15%;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  position: absolute;
  background-size: cover;
  z-index: -1;
}
.growth-bg {
  background: url("https://images.pexels.com/photos/159888/pexels-photo-159888.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260");
  background-position: center 55%;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  position: absolute;
  background-size: cover;
  z-index: -1;
}
.service-btn:hover {
  background-color: rgba(255, 255, 255, 0.747);
  color: black;
}

.services-main-hd {
  display: flex;
  width: 85%;
  flex-direction: column;
  margin-bottom: 15px;
  border-bottom: white solid 1px;
  padding-bottom: 15px;
}

.services-hdr {
  color: white;
  font-size: 50px;
}

.services-phrase {
  font-size: 20px;
  font-style: italic;
  color: white;
  text-shadow: 1px 1px 1px black;
}

.services-main-txt {
  justify-content: center;
  width: 85%;
}

.topic-box {
  margin-bottom: 15px;
}
.topic-title {
  font-size: 30px;
  color: white;
}
.topic-bullets {
  color: white;
  text-shadow: 1px 1px 1px black;
  font-size: 18px;
  font-weight: 400;
}
@media (max-width: 411px) {
  .services-content {
    flex-direction: column-reverse;
    height: unset;
  }
  .services-btns {
    width: 100%;
  }
  .service-btn {
    width: 90%;
    font-size: 20px;
  }
  .services-main-ct {
    width: 100%;
  }
  .services-container {
    margin-bottom: 55px;
  }
}

.contact-container {
  display: flex;
  justify-content: center;
  width: 100%;
  min-height: 100vh;
  background: #f5f5f5;
}

.contact-bg {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  background: url(https://images.pexels.com/photos/416673/pexels-photo-416673.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260);
  align-items: center;
  min-height: 100vh;
}

.c-form {
  display: flex;
  flex-direction: column;
  padding: 20px;
  background: rgba(0, 0, 0, 0.863);
  color: white;
  height: 70%;
  width: 90%;
  max-width: 600px;
  margin: 50px;
  border-radius: 5px;
  box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.767);
  font-weight: 600;
  font-size: 20px;
}
textarea {
  background-color: rgba(248, 248, 255, 0.699);
}
.c-form-hdr {
}

.form-name {
  width: 50%;
}

.form-services {
}

.form-company {
}

.form-ipt {
  margin: 10px 0;
}
textarea:focus + label {
  color: white;
}

label {
  color: white;
}
label:focus {
  color: white;
}
.MuiInputLabel-outlined-15 {
  z-index: 1;
  -webkit-transform: translate(14px, 20px) scale(1);
          transform: translate(14px, 20px) scale(1);
  pointer-events: none;
  color: white;
}
label.MuiFormLabel-root-16.MuiFormLabel-focused-17 {
  color: rgb(255, 229, 172);
  text-shadow: 1px 1px 1px rgba(20, 20, 20, 0.911);
}
label.MuiInputLabel-formControl-10 {
  color: white;
}

#JotFormIFrame-92088491672871 {
  height: 100vh;
}

div.formFooter.summer-sale-2018 {
  display: none;
}
.supernova {
  background: black;
}

