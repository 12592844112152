* {
  box-sizing: border-box;
}
footer {
  position: fixed;
  bottom: 0;
  background: black;
  width: 100%;
  color: white;
  display: flex;
  justify-content: center;
  padding: 5px;
  font-size: 12px;
  z-index: 3;
}
.main-container {
  display: flex;
  flex-direction: column;
  position: relative;
  background-color: rgb(245, 240, 225);
  align-items: center;
  min-height: 100vh;
}

.nav-cont {
  width: 100%;
  display: flex;
  flex-direction: column;
  background: white;
  z-index: 1;
}

.logo {
  height: auto;
  width: 20%;
}

.nav-top {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.493);
}

.nav-red {
  width: 100%;
  position: relative;
  background: rgb(231, 26, 22);

  height: 15px;
}
.nav-black {
  width: 100%;
  position: relative;
  background: rgb(0, 0, 0);
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom-left-radius: 20px;
  border-top-left-radius: 20px;
}

.nav-btn {
  color: white;
  padding: 0px 25px;
  cursor: pointer;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.nav-btn:hover {
  background-color: rgba(211, 211, 211, 0.377);
  border-bottom: 3px solid rgb(231, 26, 22);
}

a {
  text-decoration: unset;
  height: 100%;
}

@media only screen and (max-width: 420px) {
  nav {
  }
  .nav-top {
    flex-direction: column;
    display: flex;
  }
  .nav-black {
    border-radius: unset;
  }
  .main-bottom {
    width: 100%;
    font-size: 12px;
  }
  .main-bottom > .top-phrase {
    width: 90%;
    font-size: 14px;
  }
  .main-bottom > .bottom-phrase {
    width: 90%;
    font-size: 14px;
  }
  .slogan {
    width: 100%;
  }
}
