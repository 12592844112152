.checkbox{
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    width: 50px;
    height: 50px;
    margin-left: 10px;
}

.box{
    border: white 1px solid;
}

.box.tl{
    grid-column: 1;
    grid-row: 1;
}
.box.tr{
    grid-column: 2;
    grid-row: 1;
}
.box.bl{
    grid-column: 1;
    grid-row: 2;
}
.box.br{
    grid-column: 2;
    grid-row: 2;
}

.white{
    background: white;
}

.rd{
    background: rgb(231, 26, 22);
}

.blk{
    background: black;
}

.centerBox{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 30px;
    height: 30px;
    margin: 10px;
    background: black;
    border: 3px solid white;
}

.center{
    width: 50%;
    height: 50%;
}